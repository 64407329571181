<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status']"
            :form_data_seo="form_data_seo"
            ref="list"
        >
        <!-- v-auth="'change'" -->
            <template slot="left_btn">
                <a-button class="float_left"  @click="add()" type="primary">新增更新日志</a-button>
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="edit(data.record)">编辑</a>
                <a-divider type="vertical" />
                <a-popconfirm title="您确定要删除吗?" @confirm="del(data.record)">
                    <a>删除</a>
                </a-popconfirm>
            </template>
        </TableList>

        <EditPop
            ref="edit_pop"
            :width="1000"
            @handleSubmit="submit"
            :form_data="form_data"
            :visible.sync="visible"
        ></EditPop>
		<PopTableList
            rowKey="id"
            ref="pop_table_list"
            :is_created_get_list="false"
            :columns="columns_pop"
            :get_table_list="get_table_list_pop"
            :visible.sync="visible_pop"
            :slot_table_list="['address']"
			@submit="visible_pop = false"
        >
		<!-- :form_data_seo="form_data_seo_pop" -->
		<!-- @submit="pop_table_submit" -->
            <template
                slot="address"
                slot-scope="data"
            >{{data.record.province + data.record.city + data.record.district + data.record.address}}</template>
        </PopTableList>

    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import TableList from "@/components/TableList";
import { getHelpList, changeHelp, getHelp, delHelp, cateList,readUser } from "@/api/notice";
import PopTableList from "@/components/PopTableList";
const columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "版本编号",
        dataIndex: "type",
    },
    {
        title: "设备信息",
        dataIndex: "name",
    },
    {
        title: "创建人员",
        dataIndex: "username",
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

const columns_pop = [
	{
        title: "姓名",
        dataIndex: "username",
    },
    {
        title: "部门",
        dataIndex: "department",
    },
    {
        title: "岗位",
        dataIndex: "role",
	},
	{
        title: "时间",
        dataIndex: "update_time",
    },
];


export default {
    name: "NoticeList",
    components: {
        EditPop,
		TableList,
		PopTableList
    },
    data() {
        return {
			get_table_list: getHelpList,
			get_table_list_pop:readUser,
            columns,
			visible: false,
			visible_pop:false,
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                      type: "text",
                      name: "title",
                        title: "版本编号",
                        placeholder: '',
                        options: {

                        },
                        list: []
                    },
                    {
                        type: "text",
                        name: "title",
                        title: "ID",
                        options: {},
                    },
                ],
            },
			type_list: [],
			columns_pop,
            form_data: {
                title: '添加',
                list: [
                    {
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {

                        },
                    },
                    {
                        type: 'select',
                        name: 'type_id',
                        title: '公告分类',
                        options: {
                            rules: [
                                {
                                    required: true,
                                }
                            ]
                        },
                        list: [],
                    },
                    {
                        type: 'text',
                        name: 'name',
                        title: '公告标题',
                        options: {
                            rules: [
                                {
                                    required: true,
                                }
                            ]
                        },
                    },
                ]
            }
        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data.list.forEach(item => {
                if (item.name == "department_ids") {
                    item.treeData = res;

                }
            });
        });
        this.get_cate_list();
    },
    methods: {
        add() {
           this.$router.push({
               path:'/oplog/add'
           })
		},
		
        edit(record) {
            this.form_data.title = "编辑"
            getHelp({
                data: {
                    id: record.id
                }
            }).then(res => {
                let data = res.data
                this.form_data.list.forEach(item => {
                    item.options.initialValue = data[item.name];
                    if (item.name == 'department_ids') {
                        item.disabled = true;
                    }
                })
                this.visible = true;
                this.form_data.list.forEach((item, index) => {
                    if (item.type == 'editor') {
                        this.form_data.list[index].content = data[item.name]
                    }
                    this.$nextTick(res => {
                        if (item.type == 'select') {
                            let val = {};
                            val[item.name] = data[item.name];

                            this.$refs.edit_pop.setFieldsValue(val)
                        }
                    })
                })
            })
        },
        del(record) {
            delHelp({
                data: {
                    id: record.id
                },
                info: true,
            }).then(res => {

                this.$refs.list.get_list();
            })
        },
        async get_cate_list() {
            cateList({

            }).then(res => {

                let list = res.data.list.map(val => {
                    return {
                        key: val.id,
                        value: val.name
                    }
                });

                this.form_data_seo.list.forEach(item => {
                    if (item.name == 'type') {
                        item.list = list;
                    }
                })

                this.form_data.list.forEach(item => {
                    if (item.name == 'type_id') {
                        item.list = list;
                    }
                })
            })
        },
        submit(e) {
            let data = e.values;
            if (!data.id) {
                data.id = '';
                if (data.department_ids !== null) {
                    data.department_ids = data.department_ids.join(',')
                } else {
                    delete data.department_ids;
                }
            } else {
                delete data.department_ids;
            }



            changeHelp({
                data: {
                    ...data
                },
                info: true,
            }).then(res => {

                this.visible = false;
                this.$refs.list.get_list();
            })

        },

    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>